//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FacturaServices from "../../../Services/FacturaServices"

const facturaServices = new FacturaServices();
export default {
  name: "buscarDatosFiscales",
  props: ["model", "busqueda", "cliente"],
  data() {
    return {
      datosFiscalesModal: false,
      filtros: {busqueda: "", clienteId: ""},
      datosFiscales: [],
      columnas: [
        {label: 'rfc', align: 'left', field: 'rfc', headerClasses: "w20p"},
        {label: 'razón social', align: 'left', field: 'razonSocial', headerClasses: "w40p"},
        {label: 'nombre comercial', align: 'left', name: 'nombreComercial', headerClasses: "w40p"},
      ],
      datoFiscalSeleccionado: [],
    }
  },
  watch: {
    model(value) {
      if (value) this.buscarDatosFiscales();
      else
        this.onClose();
    }
  },
  methods: {
    onClose() {
      this.datosFiscalesModal = false;
      this.$emit('on-close');
    },
    onShow() {
      this.loader(true);
      this.limpiarFiltros();
      this.loader(false);
    },
    limpiarFiltros() {
      this.filtros = {busqueda: "", clienteId: ""};
    },
    buscarDatosFiscales(loader = false) {
      if (loader) this.loader(true);
      this.filtros.clienteId = _.isEmpty(this.cliente) ? "" : this.cliente;

      return facturaServices.buscarDatosFiscales(this.filtros)
        .then(datosFiscales => {
          if (datosFiscales.length === 1)
            this.obtenerDatoFiscal(datosFiscales[0]);
          else {
            this.datosFiscales = datosFiscales;
            this.datosFiscalesModal = true;
          }
        }).catch(error => {
          this.alertShow(error, "error");
          this.onClose();
        })
        .then(() => {if (loader) this.loader(false)});
    },
    seleccionarDatoFiscalRow(evt, row, index) {
      this.obtenerDatoFiscal(row);
    },
    seleccionarDatoFiscal(details) {
      this.obtenerDatoFiscal(details.rows[0]);
    },
    obtenerDatoFiscal(row) {
      this.$emit("get-dato-fiscal", row);
    }
  }
}
